import styles from "./SPADE.module.scss";

const SPADE_P = () => (
  <svg
    viewBox="0 0 60 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.spade_icon}
  >
    <path
      d="M11.5289 39.6792V11.7513V10.8183H12.4379H29.4612C39.733 10.8183 41.648 15.6433 41.648 22.0767V28.6887C41.648 35.1221 39.733 39.6792 29.4612 39.6792H11.5289ZM42.5186 28.6887V22.0767C42.5186 15.1071 40.4294 9.92468 29.4612 9.92468H10.6583V57.2421V58.1751H11.5289V57.2421V40.5728H29.4612C40.4294 40.5728 42.5186 35.6581 42.5186 28.6887"
      fill="white"
    />
    <path
      d="M31.5119 27.7059C31.5119 28.2389 31.4707 28.7349 31.3739 29.1577C31.3016 29.4753 31.1996 29.75 31.0681 29.9814C30.9691 30.1552 30.8542 30.3055 30.7146 30.4398C30.5051 30.6399 30.2377 30.8101 29.8539 30.9399C29.4709 31.0687 28.9706 31.1514 28.3295 31.1512H21.404V19.525H28.3295C29.09 19.525 29.6505 19.6411 30.0555 19.8124C30.3601 19.9417 30.5813 20.0987 30.7603 20.2818C30.8942 20.4196 31.0053 20.5745 31.1004 20.7537C31.2426 21.0213 31.3479 21.346 31.4151 21.7219C31.4828 22.097 31.5121 22.5204 31.5119 22.9701V27.7059ZM32.4208 22.9701C32.4208 20.6471 31.7244 18.592 28.3295 18.592H20.495V31.1512V32.0842H21.404H28.3295C31.7244 32.0842 32.4208 30.0291 32.4208 27.7059V22.9701Z"
      fill="white"
    />
    <path
      d="M51.2235 30.7439C51.2235 41.1088 46.523 48.1676 30.6799 48.1676H20.6691V63.0001C20.6691 65.2342 19.7117 66.2169 17.5354 66.2169H4.04268C1.86641 66.2169 0.908968 65.2342 0.908968 63.0001V5.09959C0.908968 2.86595 1.86641 1.88297 4.04268 1.88297H30.6799C46.523 1.88297 51.2235 8.94195 51.2235 19.3067V30.7439ZM48.6903 7.32721C47.0742 5.28226 44.8153 3.67311 41.8507 2.59517C38.8836 1.51514 35.2092 0.950574 30.6799 0.949951H4.04268C3.43226 0.949951 2.87516 1.01733 2.36351 1.18092C1.98073 1.30366 1.62362 1.48364 1.31174 1.72911C0.841297 2.09548 0.493874 2.60761 0.289447 3.18193C0.0826117 3.75853 0.000606783 4.39527 0 5.09959V63.0001C0 63.6267 0.0656441 64.1985 0.224817 64.7237C0.344392 65.1164 0.519717 65.4832 0.758881 65.8033C1.1158 66.2862 1.61472 66.643 2.17445 66.8526C2.73599 67.0651 3.3563 67.1493 4.04268 67.1499H17.5354C18.1456 67.1499 18.7027 67.0826 19.2144 66.919C19.5971 66.7964 19.9545 66.6163 20.2661 66.371C20.7366 66.0044 21.0842 65.4925 21.2886 64.9178C21.4955 64.3414 21.5775 63.7046 21.5781 63.0001V49.1006H30.6799C34.706 49.1006 38.056 48.6536 40.8352 47.7963C42.9188 47.1533 44.6818 46.2769 46.1489 45.1838C47.2491 44.3642 48.1813 43.4233 48.9537 42.3794C50.1134 40.8132 50.9108 39.0197 51.4142 37.0651C51.9182 35.1095 52.1323 32.9912 52.1325 30.7439V19.3067C52.1325 16.6437 51.8319 14.1604 51.1037 11.9164C50.558 10.2347 49.7684 8.68839 48.6903 7.32721"
      fill="white"
    />
  </svg>
);

export default SPADE_P;
