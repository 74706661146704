import styles from "./SPADE.module.scss";

const SPADE_D = () => (
  <svg
    viewBox="0 0 60 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.spade_icon}
  >
    <path
      d="M10.3004 57.1817V10.7184H29.2018C39.4672 10.7184 41.4225 15.7222 41.4225 22.2448V45.9234C41.4225 52.4462 39.6303 57.1817 29.2018 57.1817H10.3004ZM42.2373 45.9234V22.2448C42.2373 15.3646 40.282 9.82495 29.2018 9.82495H9.4856V58.0754H29.2018C40.4449 58.0754 42.2373 52.8036 42.2373 45.9234Z"
      fill="white"
    />
    <path
      d="M19.9501 19.7825H28.7944C29.3803 19.7825 29.8578 19.859 30.2295 19.9923C30.5091 20.0923 30.7291 20.221 30.9104 20.3763C31.1802 20.6106 31.3797 20.9067 31.5326 21.3512C31.6834 21.7943 31.7734 22.3877 31.7729 23.1385V44.851C31.7729 45.5423 31.7022 46.1052 31.5789 46.542C31.4865 46.8705 31.3672 47.128 31.226 47.3363C31.0125 47.6465 30.7511 47.8651 30.3618 48.0334C29.9741 48.1991 29.4538 48.2969 28.7944 48.2963H19.9501V19.7825ZM28.7944 49.2293C31.4016 49.2293 32.6236 47.8891 32.6236 44.851V23.1385C32.6236 20.1897 31.4016 18.8495 28.7944 18.8495H19.0994V48.2963V49.2293H19.9501H28.7944Z"
      fill="white"
    />
    <path
      d="M51.2818 47.8891C51.2818 60.3092 44.9271 66.1171 31.2397 66.1171H3.78382C1.747 66.1171 0.850724 65.1343 0.850724 62.9002V4.99995C0.850724 2.76609 1.747 1.78311 3.78382 1.78311H31.2397C44.9271 1.78311 51.2818 7.59125 51.2818 20.0111V47.8891ZM48.1744 6.80441C46.4429 4.75839 44.1463 3.26268 41.3257 2.28923C38.503 1.31474 35.1507 0.85072 31.2397 0.850098H3.78382C3.21252 0.850098 2.69112 0.917477 2.21225 1.08107C1.8542 1.20382 1.51976 1.38378 1.22788 1.62928C0.787572 1.99564 0.462411 2.50776 0.271097 3.08229C0.0773148 3.65868 0.000567902 4.29542 0 4.99995V62.9002C0 63.5268 0.0614379 64.0989 0.210782 64.6238C0.322511 65.0167 0.486616 65.3835 0.710258 65.7036C1.04469 66.1863 1.51145 66.5432 2.0353 66.7528C2.56086 66.9653 3.14144 67.0495 3.78382 67.0501H31.2397C34.7163 67.0501 37.7509 66.6827 40.3653 65.917C42.3254 65.3427 44.0499 64.5424 45.5345 63.5005C46.6472 62.7195 47.6239 61.802 48.4574 60.7502C49.7085 59.173 50.6324 57.2965 51.2372 55.1492C51.8425 53.0007 52.1323 50.5822 52.1325 47.8891V20.0111C52.1325 16.8196 51.7251 14.012 50.8681 11.5862C50.2261 9.76767 49.3283 8.16681 48.1744 6.80441"
      fill="white"
    />
  </svg>
);

export default SPADE_D;
