import styles from "./ApplicationContainer.module.scss";

type ContainerProps = {
  children?: React.ReactNode;
};

const ApplicationContainer = ({ children }: ContainerProps) => {
  return <div className={styles.applicationContainer}>{children}</div>;
};

export default ApplicationContainer;
