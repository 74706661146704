import LandingHero from "./Hero/LandingHero";
import styles from "./Landing.module.scss";

const Landing = () => (
  <main className={styles.landingContainer}>
    <LandingHero />
  </main>
);

export default Landing;
