import Logo from "../../../components/Logo/Logo";
import spadeIcons from "../../../Icons/SPADE/index";
import styles from "./LandingHero.module.scss";

export default function LandingHero() {
  const { S, P, A, D, E } = spadeIcons;

  return (
    <section className={styles.hero}>
      <menu className={styles.heroMenu}>
        <li className={`${styles.heroMenuItem}`}>
          <S />
          <span>Suicide</span>
        </li>

        <li className={`${styles.heroMenuItem}`}>
          <P />
          <span>Post Traumatic Stress Disorder</span>
        </li>

        <li className={`${styles.heroMenuItem}`}>
          <A /> <span>Anxiety</span>
        </li>

        <li className={`${styles.heroMenuItem}`}>
          <D /> <span>Depression</span>
        </li>
        <li className={`${styles.heroMenuItem}`}>
          <E /> <span>Epilepsy</span>
        </li>
      </menu>
      <Logo
        logoContainerClassName={styles.logoContainer}
        logoClassName={styles.logo}
      />
    </section>
  );
}
