import Logo from "../Logo/Logo";
import styles from "./ApplicationHeader.module.scss";

const ApplicationHeader = () => {
  return (
    <header className={styles.applicationHeader}>
      <Logo mini />
      <nav className={styles.applicationHeaderNav}>
        <div
          className={`${styles.applicationHeaderNavLink} ${styles.dropdown}`}
        >
          Media
          <div className={styles.mediaLinks}>
            <a
              href="https://podcast.spadementalhealth.com"
              className={styles.mediaLink}
            >
              Podcasts
            </a>
            <a
              href="https://www.instagram.com/spadementalhealth/"
              className={styles.mediaLink}
            >
              Instagram
            </a>
            <a
              href="https://www.tiktok.com/@spadementalhealth"
              className={styles.mediaLink}
            >
              TikTok
            </a>
            <a
              href="https://youtube.com/@spadementalhealth"
              className={styles.mediaLink}
            >
              YouTube
            </a>
          </div>
        </div>
        <a
          href="https://cash.app/$SPADEMENTALHEALTHLLC"
          className={styles.applicationHeaderNavLink}
        >
          Donations
        </a>
      </nav>
    </header>
  );
};

export default ApplicationHeader;
