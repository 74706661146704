import styles from "./Logo.module.scss";
import LogoSVG from "./LogoSVG/LogoSVG";

type LogoProps = {
  height?: number;
  width?: number;
  mini?: boolean;
  withText?: boolean;
  logoClassName?: string;
  logoContainerClassName?: string;
};

const Logo = ({ height, width, mini, withText, logoClassName, logoContainerClassName }: LogoProps) => {
  return mini ? (
    <figure className={`${styles.logoFigureMini} ${logoContainerClassName}`}>
      <LogoSVG className={logoClassName} />
    </figure>
  ) : (
    <figure className={`${styles.logoFigure} ${logoContainerClassName}`}>
      <LogoSVG height={height} width={width} className={logoClassName} />
      {withText && <figcaption className={styles.logoCaption}>
        Post-Traumatic Stress Disorder
        <br />
        Suicide | Anxiety | Depression | Epilepsy
      </figcaption>}
    </figure>
  );
};

export default Logo;
