import ApplicationHeader from "./components/ApplicationHeader/ApplicationHeader";
import ApplicationContainer from "./containers/ApplicationContainer/ApplicationContainer";
import "./App.scss";
import Landing from "./containers/Landing/Landing";
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <>
      <Helmet>
        <title>SPADE Mental Health | Home</title>
        <meta
          name="description"
          content="SPADE Mental Health provides Suicide, PTSD, Anxiety, Depression, and Epilepsy awareness for the African American community."
        />
      </Helmet>
      <ApplicationContainer>
        <ApplicationHeader />
        <Landing />
      </ApplicationContainer>
    </>
  );
}

export default App;
